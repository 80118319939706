/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesCreeper: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>AFK Journey Plague Creeper</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_creep.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Plague Creeper</h1>
          <h2>
            Primal Lord Plague Creeper guide that gives you the best teams to
            use against the boss.
          </h2>
          <p>
            Last updated: <strong>08/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Plague Creeper" />
        <StaticImage
          src="../../../images/afk/generic/creeper.webp"
          alt="Guides"
        />
        <p>
          <strong>Plague Creeper</strong> is the first Primal Lord we encounter
          in Season of AFK Journey. In this guide you will find the best teams
          to use against him!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Best teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-n"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Graveborn (Cassadee)</>}
            className="with-bg"
          >
            <h5>Team #1 - Graveborn (Cassadee)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/creep_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Bacillus</strong> from AFK Analytica Discord.
              This team requires investment into the{' '}
              <AFKItem name="Graveborn" /> Talent tree - up to the point where
              you unlock the double tile talent.{' '}
              <strong>The best performing team currently.</strong>
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="cassadee" enablePopover /> is
                the main carry and buffer (<strong>Mythic+</strong> required).
                At +10 EX she gets a big powerspike, but she already works at +5
                EX.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="carolina" enablePopover /> is
                the MDEF debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> is a
                debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                another debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Graveborn (Talene)</>}
            className="with-bg"
          >
            <h5>Team #2 - Graveborn (Talene)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/creep_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Demian</strong> from AFK Analytica Discord.
              This team requires investment into the{' '}
              <AFKItem name="Graveborn" /> Talent tree - up to the point where
              you unlock the double tile talent.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="carolina" enablePopover /> is
                the MDEF debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the healer to help Talene,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                another debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Graveborn (Lily May)</>}
            className="with-bg"
          >
            <h5>Team #3 - Graveborn (Lily May)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/creep_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Rowan, D3r4gon and Zeebo</strong> from AFK
              Analytica Discord. This team requires investment into the{' '}
              <AFKItem name="Graveborn" /> Talent tree - up to the point where
              you unlock the double tile talent.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="carolina" enablePopover /> is
                the MDEF debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                another debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> is a
                debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> is
                another debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
                <li>
                  <AFKItem name="Tidecall Spell" /> is a good alternative.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - LB (Lily May)</>}
            className="with-bg"
          >
            <h5>Team #4 - LB (Lily May)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/creep_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Bacillus</strong> from AFK Analytica Discord.
              This team requires investment into the{' '}
              <AFKItem name="Lightbearers" /> Talent tree - up to the point
              where you unlock the double tile talent. This team has a big
              damage variance - it's very RNG heavy.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="korin" enablePopover /> is the
                main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> is the
                Tidecall abuser. She needs to be M+, have EX at least +5 and her
                Charms to work. Her main role in the team is to proc the
                Tidecall Spell really fast,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> acts
                as the healer and energy battery,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as the healer and buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Tidecall Spell" /> performs the best currently.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>Coming soon!</p>
        <SectionHeader title="Tips and tricks" />
        <p>Coming soon!</p>
        <SectionHeader title="Videos" />
        <p>For more team ideas, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="1BqpkdmC8Fw" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCreeper;

export const Head: React.FC = () => (
  <Seo
    title="Plague Creeper | AFK Journey | Prydwen Institute"
    description="Primal Lord Plague Creeper guide that gives you the best teams to use against the boss."
    game="afk"
  />
);
